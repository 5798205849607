import React from 'react';
import { useStaticQuery, graphql, navigate, Link } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/Layout';
import FeaturePost from '../components/FeaturePost/featurePost';
import PostCardModern from '../components/PostCardModern/postCardModern';
import { MainButton } from '../components/Button';
import {
  BannerWrapper,
  BannerInner,
  FeaturePosts,
  Title,
} from '../components/Banner/style';
import BlogPostsWrapper, {
  PostRow,
  PostGrid,
  SeeMore,
} from '../components/Posts/style';

import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from 'reactstrap';
import {
  ContentfulArticle,
  ContentfulArticleEdge,
  ExploreQuery,
  Query,
} from '../graphql-types';
import moment from 'moment';
import Box from '../reusecore/src/elements/Box';
import BackgroundImage from 'gatsby-background-image';
import { FluidObject } from 'gatsby-image';
import Text from '../reusecore/src/elements/Text/text';
import { theme } from '../theme';
import SEO from '../components/SEO';

const ImageBanner = styled(BackgroundImage)`
  position: absolute;
  top: -35px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
`;

export interface ExplorePageProps {}

const ExplorePage: React.FunctionComponent<ExplorePageProps> = (): JSX.Element => {
  const data = useStaticQuery<ExploreQuery>(graphql`
    query Explore {
      allContentfulArticle(sort: { fields: [publishedDate], order: [DESC] }) {
        edges {
          node {
            id
            slug
            title
            subtitle
            coverImage {
              resize(
                width: 900
                height: 450
                jpegProgressive: true
                resizingBehavior: FILL
              ) {
                src
              }
            }
            publishedDate
          }
        }
      }
      featuredArticles: allContentfulArticle(
        filter: { featured: { eq: true } }
        limit: 2
      ) {
        edges {
          node {
            id
            slug
            title
            coverImage {
              fluid(maxWidth: 1000) {
                ...GatsbyContentfulFluid_noBase64
              }
            }
          }
        }
      }
      allContentfulExplore {
        edges {
          node {
            desktopCover {
              fluid(maxWidth: 1500) {
                ...GatsbyContentfulFluid_noBase64
              }
            }
            exploreCopy
            shareImage {
              fixed(
                resizingBehavior: FILL
                cropFocus: CENTER
                toFormat: JPG
                height: 630
                width: 1200
                quality: 90
              ) {
                src
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout transparentNav>
      <SEO
        metaImage={{
          src: `https:${data.allContentfulExplore.edges[0].node.shareImage.fixed.src}`,
          height: 630,
          width: 1200,
        }}
      />
      <BannerWrapper>
        <ImageBanner
          fluid={
            data.allContentfulExplore.edges[0].node.desktopCover
              .fluid as FluidObject
          }
        >
          <Container>
            <Row>
              <Col
                xs={{ size: 10, offset: 1 }}
                sm={{ size: 6, order: 2, offset: 0 }}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <Box
                  bg="whiteTrans"
                  px={[1, 4]}
                  py={[1, 4]}
                  borderRadius={3}
                  mb={[6, 0]}
                  mt={[5, 0]}
                >
                  <Text
                    as="h2"
                    fontFamily="heading"
                    fontSize={8}
                    fontWeight={4}
                    textAlign={['center', 'left']}
                    lineHeight="title"
                    color="textColor"
                    m={0}
                  >
                    Explore
                  </Text>

                  <hr
                    style={{
                      color: 'theme.colors.textColor',
                      width: '100%',
                      borderColor: theme.colors.textColor,
                      margin: '0.75rem 0 1rem 0',
                    }}
                    className="d-none d-sm-block"
                  />
                  <Text
                    fontFamily="body"
                    fontSize={0}
                    fontWeight={400}
                    mb={0}
                    textAlign="left"
                    display={['none', 'block']}
                    lineHeight="title"
                    color="textColor"
                  >
                    {data.allContentfulExplore.edges[0].node.exploreCopy}
                  </Text>
                </Box>
              </Col>
              <Col sm={{ size: 6, order: 1 }}>
                <FeaturePosts>
                  <Title>Featured Posts</Title>
                  {data.featuredArticles.edges.map(({ node }: any) => {
                    const title = node.title;
                    //Random Placeholder Color
                    const placeholderColors = [
                      '#55efc4',
                      '#81ecec',
                      '#74b9ff',
                      '#a29bfe',
                      '#ffeaa7',
                      '#fab1a0',
                      '#e17055',
                      '#0984e3',
                      '#badc58',
                      '#c7ecee',
                    ];
                    const setColor =
                      placeholderColors[
                        Math.floor(Math.random() * placeholderColors.length)
                      ];

                    return (
                      <FeaturePost
                        key={node.slug}
                        title={title}
                        image={
                          node.coverImage == null ? null : node.coverImage.fluid
                        }
                        url={`/article/${node.slug}`}
                        placeholderBG={setColor}
                      />
                    );
                  })}
                </FeaturePosts>
              </Col>
            </Row>
          </Container>
        </ImageBanner>
      </BannerWrapper>
      <BlogPostsWrapper>
        <PostRow>
          {data.allContentfulArticle.edges.map(({ node }: any) => {
            const title = node.title;
            //Random Placeholder Color
            const placeholderColors = [
              '#55efc4',
              '#81ecec',
              '#74b9ff',
              '#a29bfe',
              '#ffeaa7',
              '#fab1a0',
              '#e17055',
              '#0984e3',
              '#badc58',
              '#c7ecee',
            ];
            const setColor =
              placeholderColors[
                Math.floor(Math.random() * placeholderColors.length)
              ];

            return (
              <PostGrid key={node.slug}>
                <PostCardModern
                  key={node.slug}
                  title={title}
                  image={
                    node.coverImage == null ? null : node.coverImage.resize.src
                  }
                  imageType="resize"
                  url={`article/${node.slug}`}
                  description={node.subtitle || node.title}
                  date={moment(node.publishedDate).format('D MMM YYYY')}
                  placeholderBG={setColor}
                />
              </PostGrid>
            );
          })}
        </PostRow>
        <SeeMore>
          <Link to="page/1">
            <MainButton type="submit">See more</MainButton>
          </Link>
        </SeeMore>
      </BlogPostsWrapper>
    </Layout>
  );
};

export default ExplorePage;
